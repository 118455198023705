input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.logistica b{
    color: #F9233B;
}

.logistica a{
    text-decoration: underline;
    color: #F9233B;
}

.logistica .title {
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
}
